/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

import { useAppState } from "./app-state-context";
import { Component, type ComponentDefinition } from "./component";
import { Layout, PimoReactLayout } from "./layout";

/**
 * Views are what we refer to as pages in the app i.e DashboardPage, ReportPage, OverviewPage etc
 * @param name the view name
 * @param layout the layout type
 * A View takes a components and pushes it to a components list
 * @returns div with all the components passed to the view layed out according to the layout passed
 */
export class View<AppState, LayoutProps> {
  public name: string;
  private components: Component<AppState, any, any, any>[] = [];

  private LayoutComponent: PimoReactLayout<LayoutProps>;

  constructor(name: string, layout: Layout<LayoutProps>) {
    this.name = name;
    this.LayoutComponent = layout.getLayoutComponent();
  }

  public render(): JSX.Element {
    const RenderedView = () => {
      const appState = useAppState();
      const components = useMemo(() => {
        const visibleComponents = this.components.filter(
          (component) =>
            component.visibilityMapper == null ||
            component.visibilityMapper(appState as never)
        );
        const componentsWithMappedLayoutProps = visibleComponents.map(
          (component) => {
            if (!component.layoutPropsMapper) {
              return component;
            }

            component.layoutProps = component.layoutPropsMapper(
              appState as never
            );
            return component;
          }
        );

        return componentsWithMappedLayoutProps;
      }, [this.components, appState]);

      return <this.LayoutComponent components={components} />;
    };
    return <RenderedView />;
  }

  public addComponent<
    ComponentState,
    ComponentEventName,
    ComponentEventPayload,
  >(
    componentDefinition: ComponentDefinition<
      ComponentState,
      ComponentEventName,
      ComponentEventPayload,
      LayoutProps
    >
  ): Component<
    AppState,
    ComponentState,
    ComponentEventName,
    ComponentEventPayload,
    LayoutProps
  > {
    const component = new Component<
      AppState,
      ComponentState,
      ComponentEventName,
      ComponentEventPayload
    >(componentDefinition);
    this.components.push(component);
    return component as Component<
      AppState,
      ComponentState,
      ComponentEventName,
      ComponentEventPayload,
      LayoutProps
    >;
  }

  public setLayout(layout: Layout<LayoutProps>) {
    this.LayoutComponent = layout.getLayoutComponent();
  }
}
