import {
  EventOutlined,
  FileDownloadOutlined,
  Logout,
} from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  DefaultOverlayEventPayload,
  type DefaultOverlayProps,
  ReportingDatePopup,
} from "@pimo/pimo-components";
import { Profile } from "dlp-types";
import { getReportingDateForCalendar } from "dlp-utils";
import React from "react";

import { STRAPI_URL } from "../../env";
import { isUserAdmin } from "../../helpers/is-user-admin";

export type DLPOverlayProps = DefaultOverlayProps & {
  reportingDate?: string;
  userProfile?: Profile;
};

export type DLPOverlayEventNames =
  | DefaultOverlayEventNames
  | "overlay:toggle-date-picker"
  | "overlay:download-report"
  | "overlay:download-excel";

type DLPOverlayEventPayload =
  | { reportingDate: string }
  | DefaultOverlayEventPayload;

/**
 * Extends the {@link DefaultOverlay} by adding a half-year dropdown field next to the account menu
 */
export const DLPOverlay: PimoReactComponent<
  DLPOverlayProps,
  DLPOverlayEventNames,
  DLPOverlayEventPayload
> = ({ fireEvent, ...props }) => {
  const [openReportingDatePopup, setOpenReportingDatePopup] =
    React.useState(false);

  const headerProps: DefaultOverlayProps["header"] = {
    ...props.header,
    children: (
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          onClick={() =>
            (location.href = "mailto:isrm@allianz.com?subject=DLP:")
          }
          sx={{
            background: "#E5A537",
            color: "white",
            px: 2,
            textTransform: "none",
            ":hover": { background: "#E5A537", opacity: 0.75 },
          }}
        >
          Contact Us
        </Button>
        <ReportingDatePopup
          isOpen={openReportingDatePopup}
          setOpen={setOpenReportingDatePopup}
          reportingDate={props.reportingDate ?? ""}
          reportingCycleDates={getReportingDateForCalendar()}
          onSubmit={(value) =>
            fireEvent?.(
              "overlay:toggle-date-picker",
              value as DLPOverlayEventPayload
            )
          }
        />
      </Box>
    ),
    entries: [
      ...(isUserAdmin(props.userProfile)
        ? [
            {
              icon: EventOutlined,
              onClick: () => setOpenReportingDatePopup(true),
              text: "Reporting",
            },
          ]
        : []),
      {
        icon: FileDownloadOutlined,
        onClick: () => fireEvent?.("overlay:download-report"),
        text: "Download PDF Report",
      },
      {
        text: "Download Excel Report",
        icon: FileDownloadOutlined,
        onClick: () => window.open(`${STRAPI_URL}/api/export`),
      },
      {
        icon: Logout,
        onClick: () => fireEvent?.("overlay:logout"),
        text: "Logout",
      },
    ],
  };
  return (
    <DefaultOverlay {...props} fireEvent={fireEvent} header={headerProps} />
  );
};
