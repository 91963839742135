import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import { FilterData } from "dlp-types";
import { fireFilterEventWithTheProperPayloadBasedOnTrigger } from "dlp-utils";

import {
  ProjectOverviewTitleCardSearchBarSlot,
  ProjectOverviewTitleCardSearchBarSlotProps,
} from "./project-overview-title-card-search-bar-slot";

export type ProjectOverviewCardProps = {
  title: string;
  filterData: FilterData;
};

export const ProjectOverviewTitleCard: PimoReactComponent<
  ProjectOverviewCardProps,
  "filter:clear" | "filter:apply",
  FilterData
> = ({ fireEvent, title, filterData, ...props }) => {
  return (
    <TitleCard
      title={title}
      endSlot={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ProjectOverviewTitleCardSearchBarSlot
            {...(props as ProjectOverviewTitleCardSearchBarSlotProps)}
            handleSearchQueryChange={(value) => {
              filterData.searchQueryFilter = value;
              fireEvent?.("filter:apply", filterData);
            }}
            handleFilterChange={(event, trigger) => {
              fireFilterEventWithTheProperPayloadBasedOnTrigger(
                trigger,
                filterData,
                event,
                fireEvent
              );
            }}
            clearAllFilters={() => fireEvent?.("filter:clear")}
            filterData={filterData}
          />
        </Box>
      }
    />
  );
};
