import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Dayjs } from "dayjs";

export interface DatePickerProps {
  /**  cycle dates needed to filter in only the correct days */
  cycleDates?: string[];
  /** currently set date */
  date: string;
  /** is popup open */
  isOpen: boolean;
  /** label of the date picker */
  label: string;
  /** title of the date picker */
  title: string;
  /** handle close */
  handleClose: () => void;
  /** handle date update */
  handleDateUpdate: (date: Dayjs | null) => void;
}

export const toFormattedDateString = (date: Date): string =>
  date?.toLocaleDateString("en-US");
export const toFormattedDateTimeString = (date: Date): string =>
  date?.toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" });
export const DATE_FORMAT = "MM/DD/YYYY";

export const DatePicker: PimoReactComponent<DatePickerProps> = ({
  cycleDates,
  date,
  isOpen,
  label,
  title,
  handleClose,
  handleDateUpdate,
}: DatePickerProps) => {
  function disabledDates(date: Dayjs) {
    if (!cycleDates || !cycleDates?.length) {
      return false;
    }

    return !cycleDates.find((cycleDate) => date.isSame(cycleDate, "day"));
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} data-testid="date-popup">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          value={date}
          onChange={handleDateUpdate}
          InputProps={{ style: { height: "100%" } }}
          label={label}
          shouldDisableDate={disabledDates}
          renderInput={(params: TextFieldProps) => (
            <TextField {...params} disabled />
          )}
          inputFormat={DATE_FORMAT}
          showToolbar={false}
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
        />
      </LocalizationProvider>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
