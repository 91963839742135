import {
  Box,
  Button,
  Grid,
  GridProps,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  styled,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { Layout, PimoReactLayout } from "@pimo/pimo-app-builder";
import React from "react";
import { generatePath, Link, useParams } from "react-router-dom";

const Stage = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: "always",
  },
}));

export type TabsLayoutProps = {
  viewname?: string;
} & GridProps;

const buildTabLayoutReactComponent: (
  spacing: number,
  tabs: TabDefinition[],
  buttons:
    | {
        icon?: string;
        text: string;
        onClick: () => void;
      }[]
    | undefined,
  dropdowns?: {
    buttontitle: string;
    icon?: string;
    items: {
      dropdownTitle: string;
      icon: string;
      dropdownOnClick: () => void;
    }[];
  }[]
) => PimoReactLayout<TabsLayoutProps> =
  (spacing, tabs, buttons, dropdowns) =>
  ({ components }) => {
    const params = useParams();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentDropdown, setCurrentDropdown] = React.useState<number | null>(
      null
    );

    const tabContentComponents = components.filter(
      (component) => component.layoutProps?.viewname === params.viewname
    );

    const handleDropdownClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number
    ) => {
      setAnchorEl(event.currentTarget);
      setCurrentDropdown(index);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setCurrentDropdown(null);
    };

    return (
      <Stage
        sx={{
          padding: theme.spacing(1),
          borderRadius: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={params.viewname}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              py: "10px",
              "@media print": {
                display: "none",
              },
            }}
          >
            {tabs.map((tab) => (
              <Tab
                sx={{ padding: "10px 16px" }}
                key={tab.viewname}
                component={Link}
                label={tab.name}
                value={tab.viewname}
                to={generatePath(tab.path, params)}
              />
            ))}
          </Tabs>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0,
              "@media print": {
                display: "none",
              },
            }}
          >
            {buttons && (
              <Box sx={{ display: "flex", gap: 0 }}>
                {buttons.map((button) => (
                  <Button
                    onClick={button.onClick}
                    sx={{
                      padding: "4px 8px",
                      color: theme.palette.primary.dark,
                      borderCollapse: theme.palette.primary.dark,
                      borderRadius: "4px",
                      margin: 0,
                      display: window.location.pathname.includes("dashboard")
                        ? "inherit"
                        : "none",
                    }}
                    key={button.text}
                  >
                    <>
                      {button.icon && (
                        <Box
                          component={"img"}
                          src={button.icon}
                          sx={{ margin: 0, padding: 0 }}
                        />
                      )}
                      {button.text}
                    </>
                  </Button>
                ))}
              </Box>
            )}

            {dropdowns && (
              <Box sx={{ display: "flex", gap: 0 }}>
                {dropdowns.map((dropdown, index) => (
                  <Button
                    onClick={(e) => handleDropdownClick(e, index)}
                    sx={{
                      padding: "4px 8px",
                      margin: 0,
                      color: theme.palette.primary.dark,
                      borderCollapse: theme.palette.primary.dark,
                      borderRadius: "4px",
                    }}
                    key={dropdown.buttontitle}
                  >
                    <>
                      {dropdown.icon && (
                        <Box
                          component={"img"}
                          src={dropdown.icon}
                          sx={{ margin: 0, padding: 0 }}
                        />
                      )}
                      {dropdown.buttontitle}
                    </>
                  </Button>
                ))}

                {dropdowns.map((dropdown, index) => (
                  <Popover
                    key={index}
                    open={currentDropdown === index}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{ "@media print": { display: "none" } }}
                  >
                    <Box>
                      <List>
                        {dropdown.items.map((item, itemIndex) => (
                          <ListItem key={itemIndex}>
                            <Box
                              component={"img"}
                              src={item.icon}
                              sx={{ margin: 0, padding: 0 }}
                            />
                            <ListItemButton
                              onClick={item.dropdownOnClick}
                              sx={{ padding: 0 }}
                            >
                              <ListItemText primary={item.dropdownTitle} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Popover>
                ))}
              </Box>
            )}
          </Box>
        </Box>

        <Grid container spacing={spacing}>
          {tabContentComponents.map((component, index) => {
            const Component = component?.render();
            return (
              <Grid item key={`grid_item_${index}`} {...component.layoutProps}>
                <Component />
              </Grid>
            );
          })}
        </Grid>
      </Stage>
    );
  };

export interface TabDefinition {
  name: string;
  viewname: string;
  path: string;
}

export class TabLayout implements Layout<TabsLayoutProps> {
  constructor(
    private readonly tabs: TabDefinition[],
    private spacing = 1,
    private buttons?: {
      icon?: string;
      text: string;
      onClick: () => void;
    }[],
    private dropdowns?: {
      buttontitle: string;
      icon?: string;
      items: {
        dropdownTitle: string;
        icon: string;
        dropdownOnClick: () => void;
      }[];
    }[]
  ) {}

  getLayoutComponent() {
    return buildTabLayoutReactComponent(
      this.spacing,
      this.tabs,
      this.buttons,
      this.dropdowns
    );
  }
}
