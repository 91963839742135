import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import { MouseEventHandler } from "react";

import {
  ReportingDateIndicator,
  type ReportingDateIndicatorProps,
} from "../reporting-date-indicator/reporting-date-indicator";

export type ReportTitleCardDashboardSlotProps = {
  endDate: string;
  showEditButton?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & { reportingDateIndicator?: ReportingDateIndicatorProps };

type EditButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLocked?: boolean;
};

const EditButton = ({ isLocked, onClick }: EditButtonProps) => {
  const theme = useTheme();
  const button = (
    <Button
      disabled={isLocked}
      sx={{
        border: "1px solid",
        padding: "4px 16px",
        color: theme.palette.primary.dark,
        borderCollapse: theme.palette.primary.dark,
        borderRadius: "4px",
      }}
      onClick={onClick}
    >
      Edit {isLocked && "🔒"}
    </Button>
  );

  if (!isLocked) {
    return button;
  }

  return (
    <Tooltip title={`Locked by ...`}>
      {/* We need to render the `div` to make the tooltip work with the disabled button */}
      <div>{button}</div>
    </Tooltip>
  );
};

export const ReportTitleCardDashboardSlot = ({
  reportingDateIndicator,
  showEditButton,
  onClick,
}: ReportTitleCardDashboardSlotProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
      }}
    >
      {reportingDateIndicator && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "14px" }}>Update Status:</Typography>
          <ReportingDateIndicator {...reportingDateIndicator} />
        </Box>
      )}
      {showEditButton && <EditButton onClick={onClick} />}
    </Box>
  );
};
