import {
  EditDialogChildComponentProps,
  Form,
  TabsWithMountingPanels,
} from "@pimo/pimo-components";
import { OEProjectAttributesForEditPage } from "dlp-types";
import { capitalize } from "dlp-utils";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { EditActivitiesFormContents } from "../edit-dialog-content/edit-activities-form-content/edit-activities-form-content";
import { EditKeyInformationFormContents } from "../edit-dialog-content/edit-key-information-form-content/edit-key-information-form-content";

export interface EditDialogContentProps
  extends EditDialogChildComponentProps<OEProjectAttributesForEditPage> {
  /** submit handler */
  onSubmit: (data: OEProjectAttributesForEditPage) => void;
  /** reporting date line from oe */
  reportingDate: string;
  isAdminWithEditRights?: boolean;
}

/** contents of the edit dialog,  */
export const EditDialogContent: FC<EditDialogContentProps> = ({
  data,
  onSubmit,
  reportingDate,
}: EditDialogContentProps) => {
  const formMethods = useForm<OEProjectAttributesForEditPage>({
    defaultValues: {
      ...data,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <Form
        id="edit-dialog-form"
        data-testid="EditDialogForm"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <TabsWithMountingPanels
          panels={[
            {
              title: "Comment & Key Information",
              content: (
                <EditKeyInformationFormContents
                  data={data}
                  reportingDate={reportingDate}
                />
              ),
            },
          ].concat(
            data?.activitiesGroupedBySubdimension?.map((groupName, index) => ({
              title: `Activities ${capitalize(groupName.group)}`,
              content: (
                <EditActivitiesFormContents
                  formMethods={formMethods}
                  index={index}
                  reportingDate={reportingDate}
                  subdimension={`${capitalize(groupName.group)}`}
                />
              ),
            })) ?? []
          )}
          renderHidden={true}
        />
      </Form>
    </FormProvider>
  );
};
