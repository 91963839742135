import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React, { FC, useState } from "react";

import { DATE_FORMAT } from "../date-picker/date-picker";

export interface ReportingDatePopupProps {
  /** reporting cycle dates needed to filter in only the correct days */
  reportingCycleDates: string[];
  /** current reporting date */
  reportingDate: string;
  /** submit method for reporting date */
  onSubmit: (data: { reportingDate: string }) => void;
  /** is popup open */
  isOpen: boolean;
  /** control isOpne */
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** dialog title */
  dialogTitle?: string;
}

export const ReportingDatePopup: FC<ReportingDatePopupProps> = ({
  reportingCycleDates,
  reportingDate,
  onSubmit,
  isOpen,
  setOpen,
  dialogTitle,
}) => {
  /** disables all days except days in the current reporting cycle */
  const disableAllExceptReportingCycleDates = (date: Dayjs) =>
    !(reportingCycleDates || [])?.find((cycleDate) =>
      date.isSame(cycleDate, "day")
    );
  /** update reporting day  */
  const onUpdateReportDate = (date: Dayjs | null) => {
    if (!date) {
      return;
    }
    setUpdatedReportingDate(date);
    onSubmit({ reportingDate: date.format("YYYY-MM-DD") });
  };
  const [updatedReportingDate, setUpdatedReportingDate] = useState<Dayjs>();
  const handlePopupClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handlePopupClose}
      data-testid="reportingDatePopup"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogTitle ?? "Set Reporting Date"}
      </DialogTitle>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          value={updatedReportingDate ?? reportingDate}
          onChange={onUpdateReportDate}
          InputProps={{ style: { height: "100%" } }}
          label={"Report"}
          shouldDisableDate={disableAllExceptReportingCycleDates}
          renderInput={(params) => <TextField {...params} disabled />}
          inputFormat={DATE_FORMAT}
          showToolbar={false}
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
        />
      </LocalizationProvider>
      <DialogActions>
        <Button onClick={handlePopupClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
