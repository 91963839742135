import { Box, styled, Typography } from "@mui/material";
import { OEProjectAttributesForEditPage } from "dlp-types";
import { FC } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";

import { ActivityFormRow } from "../activity-form-row/activity-form-row";

export interface EditActivitiesFormContentsProps {
  /** generated by `useForm` (`react-hook-forms` package) */
  formMethods: UseFormReturn<OEProjectAttributesForEditPage>;
  index: number;
  reportingDate: string;
  subdimension: string;
}

const FormHeader = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
}));

/** Activities update section of the edit dialog form
 * Should be wrapped in `form`, and `FormProvider<EditDialogData>` (for the date picker)
 */
export const EditActivitiesFormContents: FC<
  EditActivitiesFormContentsProps
> = ({
  formMethods,
  index,
  reportingDate,
  subdimension,
}: EditActivitiesFormContentsProps) => {
  const { fields } = useFieldArray({
    control: formMethods.control,
    name: `activitiesGroupedBySubdimension.${index}.activities`,
  });

  return (
    <div
      key={index}
      data-testid="EditActivitiesForm"
      style={{ overflowX: "auto" }}
    >
      <FormHeader>
        <Typography variant="h5" sx={{ padding: 3 }}>
          {`Please update your activities for ${subdimension}${
            reportingDate ? ` (as of ${reportingDate})` : ""
          }`}
        </Typography>
      </FormHeader>
      {fields.map((field, findex) => (
        <ActivityFormRow
          activity={field}
          index={findex}
          activitiesIndex={index}
          key={`AFR${findex}`}
        />
      ))}
    </div>
  );
};
