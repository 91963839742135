import { Box, Card, Dialog, Divider, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown } from "@pimo/pimo-components";
import React from "react";

import { theme } from "../../theme";

type PopupWithContentEvent = "popup:open" | "popup:close";

export const ActvitiesExtraDetailsDialog: PimoReactComponent<
  {
    sectionsToDisplay: { title: string; text: string }[];
    open: boolean;
    cardTitle: string;
    entries?: { label: string; value: string | number }[];
    columnWidthPercentLabel?: number;
    solutionReason?: { title: string; text: string };
  },
  PopupWithContentEvent,
  undefined
> = ({
  cardTitle,
  open,
  sectionsToDisplay,
  fireEvent,
  entries,
  columnWidthPercentLabel = 70,
  solutionReason,
}) => {
  return (
    <Dialog
      onClose={() => fireEvent?.("popup:close")}
      open={open}
      PaperProps={{ style: { borderRadius: 12, minWidth: 800, maxWidth: 800 } }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          height: "100%",
          minWidth: 800,
          maxWidth: 800,
          overflow: "auto",
          "@media print": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            p: 2,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{cardTitle}</Typography>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
            width: "100%",
          }}
        />

        {sectionsToDisplay.map((item, index) => (
          <Box key={index} sx={{ px: 3 }}>
            <Box
              sx={{
                px: 2,
                overflow: "auto",
                scrollBehavior: "auto",
                minHeight: 130,
                maxHeight: 260,
                gap: 1,
                py: 1,
              }}
            >
              <Typography fontWeight={500}>{item.title}</Typography>
              <Markdown>{item.text}</Markdown>
            </Box>
            <Divider
              sx={{
                bgcolor: theme.palette.secondary.main,
                height: "1px",
                borderWidth: 0,
                width: "100%",
              }}
            />
          </Box>
        ))}
        <Box sx={{ px: 3 }}>
          {entries &&
            entries.map(({ label, value }) => (
              <React.Fragment key={label}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    px: 2,
                    py: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      flex: `0 0 ${columnWidthPercentLabel}%`,
                      fontWeight: 500,
                    }}
                  >
                    {label}
                  </Typography>
                  <Typography
                    sx={{
                      flex: `0 0 ${100 - columnWidthPercentLabel}%`,
                      textAlign: "right",
                    }}
                  >
                    {typeof value === "number" ? value.toLocaleString() : value}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    height: "1px",
                    borderWidth: 0,
                    width: "100%",
                  }}
                />
              </React.Fragment>
            ))}
        </Box>
        {solutionReason && (
          <Box key={"slnReason"}>
            <Box
              sx={{
                px: 5,
                overflow: "auto",
                scrollBehavior: "auto",
                minHeight: 130,
                maxHeight: 260,
                gap: 1,
                py: 1.5,
              }}
            >
              <Typography fontWeight={500}>{solutionReason.title}</Typography>
              <Markdown>{solutionReason.text ?? ""}</Markdown>
            </Box>
            <Divider
              sx={{
                bgcolor: theme.palette.secondary.main,
                height: "1px",
                borderWidth: 0,
                width: "100%",
              }}
            />
          </Box>
        )}
      </Card>
    </Dialog>
  );
};
