import { Box, Typography, useTheme } from "@mui/material";
import { PimoComponent } from "@pimo/pimo-app-builder";
import {
  LineGaugeCardState,
  NumberCardProps,
  SmallLineGaugeCard,
} from "@pimo/pimo-components";

export const CombinedMaturityActivityStatusCard: PimoComponent<{
  numberCardProps: NumberCardProps;
  lineGaugeCardProps: LineGaugeCardState;
}> = ({ numberCardProps, lineGaugeCardProps }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, height: "100%" }}
    >
      <SmallLineGaugeCard {...lineGaugeCardProps} />

      <Box data-testid="NumberCard" sx={{ height: "100%" }}>
        <Box
          sx={{
            background:
              numberCardProps.background ?? theme.palette.primary.main,
            borderRadius: "12px",
            display: "flex",
            p: 2,
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            {numberCardProps.iconSrc && (
              <Box
                component="img"
                sx={{
                  background: "white",
                  borderRadius: "12px",
                  width: "36px",
                  height: "36px",
                  padding: "5px",
                  mr: 2,
                }}
                src={numberCardProps.iconSrc}
              />
            )}
            <Typography
              sx={{
                color: theme.palette.secondary.contrastText,
                lineBreak: "auto",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {numberCardProps.title}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              flex: "1 1 100%",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.secondary.contrastText,
                fontSize: "80px",
              }}
            >
              {numberCardProps.number}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
