import {
  App,
  DeriveTableProps,
  Plugin,
  Table,
  View,
} from "@pimo/pimo-app-builder";
import {
  ChipCell,
  DateCell,
  GridLayout,
  GridLayoutProps,
  IndexCell,
  ProgressBarCell,
  SmileyCell,
  TextCardCell,
} from "@pimo/pimo-components";
import { Maturity } from "dlp-types";
import {
  areAllActivitiesCompleted,
  DLP_COLORS,
  formatDate,
  formatDateTime,
  getColorAndIconForReportingDate,
  getTextAndColorForMaturityCardBasedOnMaturityLevel,
} from "dlp-utils";

import { DLPAppState } from "../app";
import { ProjectOverviewTitleCard } from "../components/project-overview-title-card/project-overview-title-card";
import { APP_ROUTES } from "../constants";
import {
  fetchOeProjectOverview,
  fetchOEProjects,
} from "../helpers/dlp-fetch-helper";

export class OverviewPlugin implements Plugin<DLPAppState, DLPAppState> {
  route?: Plugin<DLPAppState, DLPAppState>["route"];
  private view?: View<DLPAppState, GridLayoutProps>;
  private app?: App<DLPAppState>;
  onRegister(app: App<DLPAppState>): void {
    this.view = app.createView({
      name: "Overview",
      layout: new GridLayout(),
    });
    this.app = app;
    this.buildTitleCard();

    this.buildTable();

    this.route = app.createRoute({
      path: APP_ROUTES.overview,
      view: this.view,
    });

    this.route.on("load", async () => {
      const [oeProjects, oeProjectOverview] = await Promise.all([
        fetchOEProjects(),
        fetchOeProjectOverview(),
      ]);

      app.setAppState({
        ...app.getAppState(),
        oeProjectOverview,
        oeProjects,
        filterDialogPossibleOptions: {
          oeProjects: oeProjects.map((oe) => oe.name),
          owners: oeProjects
            .map(
              (oe) =>
                oe.team.find(
                  (teamMember) => teamMember.role?.name === "DLP Contact"
                )?.person?.name ?? ""
            )
            .filter(Boolean),
        },
      });
    });
  }

  private buildTitleCard() {
    if (!this.view) {
      return;
    }

    const titleComponent = this.view.addComponent({
      component: ProjectOverviewTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    titleComponent.mapState((state) => ({
      title: `Project Overview (${
        state.oeProjectOverview?.numberOfOEs ?? 0
      } OEs)`,
      oeProjectsOptions: state.filterDialogPossibleOptions?.oeProjects,
      ownersOptions: Array.from(
        new Set(state.filterDialogPossibleOptions?.owners)
      ),
      filterData: state.filterDialogData ?? {
        projectsFilter: [],
        overdueFilter: [],
        govLeadFilter: [],
        atRiskFilter: [],
        updateStatusFilter: [],
        maturityFilter: [],
        searchQueryFilter: "",
        dueYearFilter: [],
        dueQuarterFilter: [],
        regionFilter: [],
        localFilter: [],
      },
    }));

    titleComponent.on("filter:clear", async () => {
      if (!this.app) {
        return;
      }

      const [oeProjects, oeProjectOverview] = await Promise.all([
        fetchOEProjects(),
        fetchOeProjectOverview(),
      ]);

      const state: DLPAppState = this.app.getAppState();

      this.app.setAppState({
        ...state,
        oeProjects: oeProjects ?? [],
        oeProjectOverview,
        filterDialogData: {
          projectsFilter: [],
          overdueFilter: [],
          govLeadFilter: [],
          atRiskFilter: [],
          updateStatusFilter: [],
          maturityFilter: [],
          searchQueryFilter: "",
          dueYearFilter: [],
          dueQuarterFilter: [],
          regionFilter: [],
          localFilter: [],
        },
      });
    });

    titleComponent.on("filter:apply", async ({ payload }) => {
      if (!this.app) {
        return;
      }

      const state: DLPAppState = this.app.getAppState();

      const oeProjects = await fetchOEProjects(
        payload,
        state.program?.reportingDate
      );
      const oeProjectOverview = await fetchOeProjectOverview(
        payload,
        state.program?.reportingDate
      );

      this.app.setAppState({
        ...state,
        filterDialogData: payload,
        oeProjects: oeProjects ?? [],
        oeProjectOverview,
      });
    });
  }

  private buildTable() {
    if (!this.view) {
      return;
    }

    const tableDefinition = [
      { component: IndexCell },
      { component: TextCardCell },
      { component: TextCardCell },
      { component: ChipCell },
      { component: ProgressBarCell },
      { component: ChipCell },
      { component: ChipCell },
      { component: ChipCell },
      { component: DateCell },
      { component: SmileyCell },
    ] as const;

    const table = new Table(tableDefinition, "overview");

    const tableComponent = this.view.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        xs: 12,
      },
    });

    tableComponent.mapState((state) => {
      const { reportingDate = "" } = state.program ?? {};
      return {
        tableProps: {
          sx: { height: window.screen.height < 1300 ? "76vh" : "88vh" },
        },

        data:
          state?.oeProjects?.map(
            (oe, index): DeriveTableProps<typeof table>["data"][number] => {
              const { icon } = getColorAndIconForReportingDate(
                oe.oeEarliestUpdatedAt ?? "",
                reportingDate,
                areAllActivitiesCompleted(oe.activities)
              );

              return {
                rowProps: {
                  onClick: () => {
                    if (!oe?.id) {
                      return;
                    }
                    this.app?.navigate(`/reports/${oe.id}/dashboard`);
                  },
                },
                columnProps: [
                  {
                    indexValue: index + 1,
                    cardProps: { sx: { maxWidth: 60, minWidth: 60 } },
                  },
                  {
                    header: "OE",
                    body: oe.name,
                    cardProps: { sx: { maxWidth: 190, minWidth: 190 } },
                    tooltipShow: true,
                    tooltipTitle: oe.name,
                    bodyProps: {
                      sx: {
                        lineHeight: "1.2em",
                        height: "1.2em",
                        overflow: "hidden",
                        display: "-webkit-box",
                        lineBreak: "anywhere",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      },
                    },
                  },
                  {
                    header: "DLP Contact",
                    tooltipShow: true,
                    tooltipTitle:
                      oe.team.find(
                        (teamMember) => teamMember.role?.name === "DLP Contact"
                      )?.person?.name ?? "",
                    body:
                      oe.team.find(
                        (teamMember) => teamMember.role?.name === "DLP Contact"
                      )?.person?.name ?? "",
                    cardProps: {
                      sx: {
                        maxWidth: 190,
                        minWidth: 190,
                      },
                    },
                    bodyProps: {
                      sx: {
                        lineHeight: "1.2em",
                        height: "1.2em",
                        overflow: "hidden",
                        display: "-webkit-box",
                        lineBreak: "anywhere",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      },
                    },
                  },
                  {
                    header: "Maturity",
                    body: `Maturity ${oe._calculated?.maturity ?? 0}`,
                    cardProps: { sx: { maxWidth: 150, minWidth: 150 } },

                    chipProps: {
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: `${
                          getTextAndColorForMaturityCardBasedOnMaturityLevel(
                            (oe._calculated?.maturity ?? 0) as Maturity
                          ).colorScheme.chipColor
                        }`,
                        color: `${
                          getTextAndColorForMaturityCardBasedOnMaturityLevel(
                            (oe._calculated?.maturity ?? 0) as Maturity
                          ).colorScheme.textColor
                        }`,
                        maxWidth: 100,
                        minWidth: 100,
                      },
                    },
                  },
                  {
                    header: `Act. comp. ${
                      oe._calculated
                        ?.completedActivitiesPerOEPerDimensionAndPerPriority
                        ?.completedActivities ?? 0
                    }/${
                      oe._calculated
                        ?.completedActivitiesPerOEPerDimensionAndPerPriority
                        ?.totalNumberOfActivities ?? 0
                    }`,
                    progress: Math.round(
                      oe._calculated
                        ?.completedActivitiesPerOEPerDimensionAndPerPriority
                        ?.completionProgress ?? 0
                    ),
                    progressColor: DLP_COLORS.babyBlue,
                    cardProps: {
                      sx: {
                        minWidth: 180,
                        maxWidth: 180,
                        flex: 1,
                      },
                    },
                  },
                  {
                    header: "Act. overdue",
                    body: (oe.numberOfOverdueActivities ?? 0).toString(),
                    cardProps: { sx: { maxWidth: 100, minWidth: 100 } },
                    chipProps: {
                      sx: {
                        backgroundColor: DLP_COLORS.babyBlue,
                        color: "white",
                        borderRadius: "6px",
                        maxWidth: 60,
                        minWidth: 60,
                      },
                    },
                  },
                  {
                    header: "Act. w/ risk",
                    body: (
                      oe._calculated?.numberOfActivitiesWithRiskDefined ?? 0
                    ).toString(),
                    cardProps: { sx: { maxWidth: 100, minWidth: 100 } },
                    chipProps: {
                      sx: {
                        backgroundColor: DLP_COLORS.babyBlue,
                        color: "white",
                        borderRadius: "6px",
                        maxWidth: 60,
                        minWidth: 60,
                      },
                    },
                  },
                  {
                    header: "Act. w/ loc. sln.",
                    body: (
                      oe._calculated?.numberOfActivitiesWithLocalSolutions ?? 0
                    ).toString(),
                    cardProps: { sx: { maxWidth: 140, minWidth: 140 } },
                    chipProps: {
                      sx: {
                        backgroundColor: DLP_COLORS.babyBlue,
                        color: "white",
                        borderRadius: "6px",
                        maxWidth: 60,
                        minWidth: 60,
                      },
                    },
                  },
                  {
                    header: "Planned Compl. Date",
                    date: oe.overallCompletionDateBasedOnActivities
                      ? formatDate(oe.overallCompletionDateBasedOnActivities)
                      : "n/a",
                    icon: "calendar-outlined.svg",
                  },
                  {
                    icon: icon,
                    iconColor: "white",
                    textOnHover: oe.oeEarliestUpdatedAt
                      ? `as of ${formatDateTime(oe.oeEarliestUpdatedAt)} ${
                          oe.oeEarliestUpdatedBy
                            ? `by ${oe.oeEarliestUpdatedBy}`
                            : ""
                        }`
                      : "",
                  },
                ],
              };
            }
          ) ?? [],
      };
    });
  }
}
