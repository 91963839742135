import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import {
  DefaultOverlayPartialAppState,
  initialDefaultOverlayState,
} from "@pimo/pimo-components";
import {
  ActivityAttributes,
  Attachment,
  Dashboard,
  FilterData,
  OEProjectAttributes,
  OEProjectAttributesForEditPage,
  OEProjectOverview,
  Profile,
  ProgramAttributes,
} from "dlp-types";

import { APP_KEY } from "../app-key";
import { STRAPI_URL } from "./env";
import { createOverlay } from "./helpers/create-overlay";
import {
  fetchOEProjectsForNavigation,
  fetchProgram,
  fetchUserProfile,
} from "./helpers/dlp-fetch-helper";
import { DashboardPlugin } from "./pages/dashboard-plugin";
import { HomePlugin } from "./pages/home-plugin";
import { NoDataPlugin } from "./pages/no-data.plugin";
import { OverviewPlugin } from "./pages/overview-plugin";
import { PDFDashboardPlugin } from "./pages/pdf-dashboard-plugin";
import { ReportPlugin } from "./pages/report-plugin";
import { theme } from "./theme";

export type DLPAppState = DefaultOverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  UniversalAppState & {
    sideMenuEntries?: OEProjectAttributes[];
    dashboard?: Dashboard;
    oeProjects?: OEProjectAttributes[];
    oeProjectsForNavigation?: OEProjectAttributes[];
    program?: ProgramAttributes;
    oeProjectOverview?: OEProjectOverview;
    filterDialogData?: FilterData;
    filterDialogPossibleOptions?: {
      oeProjects: string[];
      owners: string[];
    };
    currentOEProject?: OEProjectAttributes;
    reportPluginPopupOpen?: boolean;
    reportPluginPopupContent?: ActivityAttributes;
    attachments?: Attachment[];
    editDialogData?: OEProjectAttributesForEditPage;
  };

// Plugins
const loginPlugin = new LoginPlugin<Profile, DLPAppState>(
  "/",
  STRAPI_URL,
  "Data Leakage Prevention (DLP)"
);

const homePlugin = new HomePlugin<DLPAppState>();
const dashboardPlugin = new DashboardPlugin();
const overviewPlugin = new OverviewPlugin();
const reportPlugin = new ReportPlugin();
const pdfDashboardPlugin = new PDFDashboardPlugin(
  dashboardPlugin,
  reportPlugin
);
// App
const app = App.create<DLPAppState>(
  composeInitialState<DLPAppState>({
    ...initialDefaultOverlayState,
    ...loginPlugin.getInitialState(),
    isLoading: true,
    isSidebarOpen: true,
  }),
  APP_KEY
);
app.setTheme(theme);

// Overlay
const overlay = createOverlay(app);

// LIFECYCLES
overlay.on("overlay:logout", () => {
  loginPlugin.logout();
});

app.registerPlugin(loginPlugin);
app.registerPlugin(dashboardPlugin);
app.registerPlugin(overviewPlugin);
app.registerPlugin(homePlugin);
app.registerPlugin(reportPlugin);
app.registerPlugin(pdfDashboardPlugin);
app.registerPlugin(new NoDataPlugin<DLPAppState>());

loginPlugin.on("login-status-changed", async (evt) => {
  if (!evt.payload?.isLoggedIn) {
    return;
  }

  const [oeProjectsForNavigation, program, userProfile] = await Promise.all([
    fetchOEProjectsForNavigation(),
    fetchProgram(),
    fetchUserProfile(),
  ]);

  const state = app.getAppState();

  app.setAppState({
    ...state,
    isLoading: false,
    sideMenuEntries: oeProjectsForNavigation ?? [],
    program,
    userProfile,
  });
  // lazily populate the pdf page after fetching all data
  pdfDashboardPlugin.render();
});

const RenderedApp = app.render();

export default RenderedApp;
