import { Box, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { Card, CardContent, type CardProps } from "../../molecules";

export interface DonutVerticalChartCardProps {
  /** Global Title of the Card, displayed to the left */
  title: string;
  /** Value of series */
  series: number[];
  /** Chart Title of the Graphic, displayed to the bottom */
  chartTitle?: string;
  /** Chart's colors */
  backgroundColor: string[];
  legendIcons?: string[];
  /** Chart's colors */
  labels?: readonly string[];
  /**
   * colors schemes for the label badges, if this property is passed
   * the legend on the side will render badges instead of simple text
   */
  labelBadgesColors?: { chipColor: string; textColor: string }[];
  /** Chart's tooltips Labels */
  toolTipLabels?: readonly string[];
  toolTipEnabled?: boolean;
  /** show labels inside chart */
  showLabels?: boolean;
  /** enable hover effects over chart */
  enableHover?: boolean;
  /** (optional) more styling props */
  cardProps?: CardProps;
  /** fallback text */
  fallbackText?: string;
  /** footer text  */
  footerText?: string;
  /** offset labels */
  labelsOffset?: number;
  /** value to be displayed in center of donut */
  centerText?: string;
}

export const DonutVerticalChartCard: PimoReactComponent<
  DonutVerticalChartCardProps
> = ({
  title,
  series,
  backgroundColor,
  labels,
  toolTipLabels = [],
  showLabels,
  toolTipEnabled,
  enableHover,
  cardProps = {},
  labelBadgesColors,
  legendIcons,
  fallbackText,
  footerText,
  labelsOffset,
  centerText,
}) => {
  const options: ApexOptions = {
    labels: toolTipLabels as unknown as string[],
    colors: backgroundColor,
    legend: { show: false },
    dataLabels: { enabled: true, offsetX: 100, offsetY: 100 },
    tooltip: {
      enabled: toolTipEnabled,
      fillSeriesColor: false,
      theme: "light",
    },
    chart: {
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: labelsOffset ?? 0,
        },
        donut: {
          size: "70%",
          labels: {
            show: showLabels,
            name: {
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
              label: centerText,
            },
            value: {
              show: false,
            },
          },
        },
      },
    },
  };

  if (!enableHover) {
    options.states = {
      hover: {
        filter: {
          type: "none",
        },
      },
    };
  }

  return (
    <Card {...cardProps} data-testid="DonutChart" title={title}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: 2,
            xl: 0,
          },
          "@media print": {
            pt: 2,
          },
        }}
      >
        <Box
          sx={{
            flex: "1 1 100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 230,
            "@media print": {
              display: "none",
            },
          }}
        >
          {series.some((entry) => entry !== 0) ? (
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              className="apex-charts"
            />
          ) : (
            <Box
              className="apex-charts"
              sx={{
                flex: "1 1 100%",

                color: "grey",
                verticalAlign: "center",
                textAlign: "center",

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: 26 }}> {fallbackText}</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            mt: 2,
            minWidth: {
              xs: "calc(100% - 80px)",
              xl: labelBadgesColors ? 150 : "230px",
            },
            mx: "auto",
            "@media print": {
              margin: "unset",
            },
          }}
        >
          {labels?.map((label, index) =>
            labelBadgesColors ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={index}
              >
                <Box
                  sx={{
                    lineHeight: "1.2rem",
                    fontSize: "12px",
                    height: "1.2rem",
                    textAlign: "center",
                    fontWeight: 500,
                    flex: "1 1 100%",
                    borderRadius: 12,
                    maxWidth: 90,
                    backgroundColor: labelBadgesColors[index].chipColor,
                    color: labelBadgesColors[index].textColor,
                  }}
                >
                  {label}
                </Box>
                <Typography variant={"subtitle2"}>{series[index]}</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                }}
                key={index}
              >
                {legendIcons?.[index] ? (
                  <Box
                    component={"img"}
                    src={legendIcons[index]}
                    sx={{ marginLeft: -0.5 }}
                  />
                ) : (
                  <Box
                    fontSize="small"
                    sx={{
                      borderRadius: "100%",
                      height: "15px",
                      width: "15px",
                      flex: "0 0 15px",

                      background: backgroundColor[index],
                      border:
                        backgroundColor[index]?.toUpperCase() === "#FFFFFF"
                          ? "#003781 1px solid"
                          : "",
                    }}
                  />
                )}

                <Typography component="span" sx={{ flex: "1 1 100%" }}>
                  {label}
                </Typography>
                <Typography variant={"subtitle2"}>{series[index]}</Typography>
              </Box>
            )
          )}
        </Box>
        {footerText && (
          <Typography sx={{ p: 2, fontStyle: "italic" }}>
            {footerText}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DonutVerticalChartCard;
